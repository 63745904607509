<h2 class="mb-4 mt-1 text-2xl">Teams</h2>

@if (teams.length) {
  <mat-accordion>
    @for (team of teams; track team.id) {
      <mat-expansion-panel>
        <mat-expansion-panel-header class="flex-grow">
          <mat-panel-title class="flex-grow">
            <fa-icon
              [icon]="['fas', 'folder']"
              class="mr-5 text-lg text-neutral-700 dark:text-neutral-300"
            ></fa-icon>
            {{ team.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <h3 class="mb-3 text-lg">Team Lead</h3>
        <div class="grid grid-cols-fill-80 gap-5">
          <app-user-card
            [user]="team.owner"
            [showAutoName]="true"
            [isTeamLead]="true"
          ></app-user-card>
        </div>

        <h3 class="mb-3 mt-6 text-lg">Members</h3>
        <div class="grid grid-cols-fill-80 gap-5">
          @for (user of team.members; track user.id) {
            @if (user.id !== team.owner.id) {
              <app-user-card
                [user]="user"
                [showAutoName]="true"
              ></app-user-card>
            }
          }
        </div>
        @if (team.members.length === 0) {
          <!-- 1 since we remove the team lead -->
          <mat-toolbar class="placeholder">
            No other users on this team.
          </mat-toolbar>
        }
      </mat-expansion-panel>
    }
  </mat-accordion>
} @else {
  <div class="absolute flex h-[50vh] w-full items-center justify-center">
    <mat-spinner></mat-spinner>
  </div>
}
